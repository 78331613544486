<template>
  <div class="box">
    <h4>请选择所在社区:</h4>
    <van-radio-group v-model="radio">
      <van-cell-group>
        <van-cell :title="item.name" clickable @click="radio = index" v-for="(item,index) in list" :key="item.id">
          <template #right-icon>
            <van-radio :name="index" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <van-button type="info" size="large" class="btn" @click="btnclick" round >进入居民果果</van-button>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  data() {
    return {
      radio:'',
      list: []
    }
  },
  methods: {
    btnclick () {
      if (this.radio === '') {return this.$toast.fail('失败:请先选择社区!!!')}
      let communityId = this.list[this.radio].id
        this.$http({
        url: this.$http.adornUrl('/zlb/login/zlbappBind'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: communityId,
          userId:this.$globalData.userInfo.userId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          console.log(data);
              this.$cookies.set('token', data.userForm.token)
              this.$cookies.set('orgId', data.userForm.orgId)
              console.log(this);
              Vue.prototype.$orgId = Number(data.userForm.orgId)
              Vue.prototype.$userId = data.userForm.userId
              Vue.prototype.$userName = data.userForm.userName
              Vue.prototype.$orgName = data.userForm.orgName
              this.$cookies.set('userId', data.userForm.userId)
              aplus_queue.push({action: 'aplus.setMetaInfo', arguments: ['_hold', 'BLOCK']});
              aplus_queue.push({action: "aplus.setMetaInfo", arguments: ["_user_nick", data.userForm.zlb_userName]});
              aplus_queue.push({action: "aplus.setMetaInfo", arguments: ["_user_id", data.userForm.zlb_userId]});
              aplus_queue.push({action: 'aplus.setMetaInfo', arguments: ['_hold', 'START']});
          this.$router.push('/home')
        }else {
        }
      })
    }
  },
  created () {
    if(this.$orgList[0].labelId) {
      this.$orgList.forEach(item => {
        item['id'] = item.labelId
        item['name'] = item.labelName
      });
    }
    this.list = this.$orgList
  }
}
</script>
<style lang="scss" scoped>
.van-cell__title {
  span {
    font-size: 36px;
  }
}
.box {
  min-height: 100vh;
  background-color: #fff;
  padding: 50px 30px;
  h4 {
    margin: 50px 0;
    font-size: 36px;
    font-weight: 400;
  }
  .btn {
    margin-top: 700px;
  }
}
</style>
